.loginPage {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 10px;
}

.loginPage .flowerLeft {
  position: absolute;
  width: 350px;
  left: -88px;
  bottom: -50px;
  z-index: -1;
}

.loginPage .flowerRight {
  position: absolute;
  width: 250px;
  top: -73px;
  right: -100px;
  z-index: -1;
}

.loginPageOut {
  position: relative;
  max-width: 650px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 2px 16.8px 3.2px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 265px;
}

.loginPageIn {
  padding: 100px 60px 50px;
  border: 1px solid #a3888c;
  position: relative;
  z-index: 2;
  border-radius: 265px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginPageIn .title {
  position: relative;
  margin-bottom: 40px;
}

.loginPageIn .title .flower {
  position: absolute;
  width: 70px;
  top: -40px;
  right: 30%;
}

.loginPageIn .title h1 {
  font-size: 30px;
  text-align: center;
}

.loginPage label {
  margin-top: 15px;
}

.loginPage input {
  border: none;
  border-bottom: 1px solid rgb(170, 170, 170);
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  padding: 3px 8px 8px;
}

.loginPage input:focus {
  border: none;
  border-bottom: 1px solid goldenrod;
}

.loginPage .error {
  color: rgb(177, 8, 8);
  font-size: 13px;
}

.loginPage .login {
  display: block;
  padding: 10px 50px;
  border-radius: 30px;
  font-family: vazir !important;
  margin: 35px auto 25px 0;
  background-color: #879e98;
  color: #fff;
  transition: all 0.3s;
}

.loginPage .login:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.loginPage .backBtn {
  position: absolute;
  border: 1px solid #879e98;
  left: 20px;
  top: 20px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #879e98;
  transition: all 0.3s;
}

.loginPage .backBtn:hover {
  background-color: #879e98;
  color: #fff;
}

@media (max-width: 500px) {
  .loginPage .flowerLeft {
    width: 220px;
    left: 0px;
    bottom: -94px;
  }

  .loginPage .flowerRight {
    width: 164px;
    top: -110px;
    right: 9px;
  }
}
