.cartContainer {
  margin: 150px auto;
  border-radius: 14px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;
  max-width: 700px;
}

.cartContainer .cost {
  font-size: 15px;
  line-height: 38px;
  /* text-decoration: line-through; */
  color: #373737;
  /* margin-bottom: 12px !important; */
}

.cartContainer .off {
  font-size: 20px;
  line-height: 40px;
  color: #323232;
  margin-bottom: 20px !important;
}

.cartContainer .cartItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #b9b9b98b;
  border-radius: 4px;
  margin-top: 20px;
}

.cartContainer .cartItem .text {
  color: #1a0060;
}

.cartContainer .cartItemOff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  /* border-bottom: 1px solid #b9b9b9; */
}

.cartContainer .cartItemOff .text {
  color: #8d8d8d;
}

.cartContainer .boxInput {
  position: relative;
  width: 200px;
  overflow: hidden;
  margin: 30px 0 10px;
  border: 1px solid #e9e9e9a5;
  border-radius: 4px;
}

.cartContainer .boxInput button {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  padding: 0 15px;
}

.cartContainer .boxInput input {
  text-align: left;
  border: none;
}

.pay{
  width: 100%;
  padding: 10px !important;
  border-radius: 5px !important;
  background-color: #d63031 !important;
  color: #fff !important;
}

.pay:disabled {
  color: rgb(78, 78, 78) !important;
  background-color: #c3c3c3ae !important;
  cursor: not-allowed !important;
}
