.cardPage5 {
  background-image: url("../../../../public/asset/media/pic/cart5/cart5-2.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage5 .sidebarCart {
  background-image: none;
  background-color: #1f8078;
}

.cardPage5 .sidebarCardMobile {
  background-color: #1f8078;
}

.cardPage5 .sidebarCart li {
  color: #fff;
}

.cardPage5 .sectionOne {
  background-color: transparent;
  color: #fff;
  border: 4px solid #ecd08c;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
}

.cardPage5 .sectionOne .cardImage1,
.cardPage5 .sectionOne .cardImage2 {
  display: none;
}

.cardPage5 .sectionOne .arosImage,
.cardPage5 .sectionOne .damadImage {
  border-color: #ecd08c;
}

.cardPage5 .sectionOne .cardImage1 {
  width: 40%;
}

.cardPage5 .sectionOne .cardImage2 {
  width: 40%;
}

.cardPage5 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage5 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage5 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
  border-right: 1px solid #1f8078;
  border-left: 1px solid #1f8078;
}

.cardPage5 .sectionOne .boxDetail .detail3 {
  margin-right: 8px;
}

/* .cardPage5 .sectionOne .boxDetail .detail1,
.cardPage5 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #1F8078;
  border-top: 1px solid #1F8078;
} */

.cardPage5 .sectionTwo .talar,
.cardPage5 .sectionTwo .address {
  color: #fff;
}

.cardPage5 .sectionTree {
  background-image: none;
  background-color: transparent;
  color: #fff;
  border: 4px solid #ecd08c;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
}

.cardPage5 .sectionTree textarea,
.cardPage5 .sectionTree input {
  background-color: transparent;
  color: #fff;
}

.cardPage5 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage5 .sectionTree .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
}

@media (max-width: 500px) {
  .cardPage5 .sectionTree .cardImage1 {
    width: 57%;
  }

  .cardPage5 .sectionTree .cardImage2 {
    width: 60%;
  }
}

.cardPage5 .sectionTree .send {
  background-color: #1f8078;
}

.cardPage5 .sectionSeven {
  background-image: none;
  background-color: transparent;
  color: #fff;
  border: 4px solid #ecd08c;
  overflow: hidden;
  width: 500px;
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
}

.cardPage5 .sectionSeven .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage5 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
}

@media (max-width: 500px) {
  .cardPage5 .sectionSeven .cardImage1 {
    width: 57%;
  }

  .cardPage5 .sectionSeven .cardImage2 {
    width: 60%;
  }
}

.cardPage5 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #1f8078a7;
  border-color: #1f8078a7;
}

.cardPage5 .sectionSeven .send {
  background-color: #1f8078;
}

.cardPage5 ._qWTcH {
  --fcc-background: #ecd08c !important;
  --fcc-separator-color: #ecd08c !important;
  --fcc-label-color: #ecd08c !important;
}

.cardPage5 .portfolio #portfolio-flters li:hover,
.cardPage5 .portfolio #portfolio-flters li.filter-active {
  color: #1f8078a7;
}

.cardPage5 .guestName {
  color: #ffffff;
  border-bottom: 1px solid #fff;
}
