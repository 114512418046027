.footer {
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 30px;
}

.footer .title {
  margin-bottom: 15px;
  position: relative;
}

.footer .title h2 {
  font-size: 30px;
  color: rgb(72, 65, 57);
  background-color: #fff;
  padding: 0 25px;
}

.footer .desc {
  width: 450px;
  max-width: 90%;
  color: rgb(72, 65, 57);
  text-align: center;
  margin-bottom: 20px !important;
}

.footer .line {
  position: absolute;
  border-bottom: 2px solid rgb(113, 102, 92);
  width: 180%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.footer .aroosiOnline {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 20rem;
  line-height: 150px;
  z-index: -1;
  color: #fff5f0;
  bottom: 2%;
  font-family: Parisienne !important;
}

.footer .flowerImg {
  max-width: 70%;
}

@media (max-width: 500px) {
  .footer .aroosiOnline {
    font-size: 8rem;
    line-height: 28px;
  }
  .footer .title h2 {
    font-size: 21px;
  }
}
