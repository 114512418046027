.planPage {
  max-width: 900px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 110px auto;
  padding: 150px 20px 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff5f0;
}

.planPage .flowerLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 600px;
  max-width: 100%;
  z-index: 2;
}

.planPage .flowerRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 530px;
  max-width: 100%;
  z-index: 2;
}

.planPage .flowerBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.planPage .disable {
  color: rgb(200, 200, 200);
  text-decoration: line-through;
}

.planPage .addToCart {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 30px;
  /* border: 1px solid #c2c2c2; */
  background-color: #a43043;
  color: rgb(240, 248, 255);
  transition: all 0.3s;
  position: relative;
  z-index: 2;
}

.planPage .addToCart:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-5px);
  border: none;
}

.planPage .cost {
  font-size: 33px;
  line-height: 38px;
  text-decoration: line-through;
  color: #e1e1e1;
  margin-bottom: 12px !important;
}

.planPage .off {
  font-size: 38px;
  line-height: 40px;
  color: #323232;
  margin-bottom: 20px !important;
}
