.cardPage9 {
  background-image: url("../../../../public/asset/media/pic/cart9/cart9-3.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage9 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #90a171;
  padding: 20px;
  background-image: none;
}

.cardPage9 .sidebarCart li {
  color: #fff !important;
}
.cardPage9 .sidebarCardMobile {
  background-color: #90a171;
  color: #fff;
}

.cardPage9 .sectionOne {
  background-color: #eee;
  border-radius: 40px 0 40px 0;
}

.cardPage9 .sectionOne .arosImage {
  border-color: #90a171;
}

.cardPage9 .sectionOne .damadImage {
  border-color: #90a171;
}

.cardPage9 .sectionOne .cardImage1 {
  width: 60%;
}

.cardPage9 .sectionOne .cardImage2 {
  width: 60%;
}

.cardPage9 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage9 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage9 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
}

.cardPage9 .sectionOne .boxDetail .detail1,
.cardPage9 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #90a171;
  border-top: 1px solid #90a171;
}

.cardPage9 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
  border-radius: 50px 0 50px 0;
}

.cardPage9 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: none;
  top: 0;
  width: 30%;
}

.cardPage9 .sectionTree .cardImage2 {
  position: absolute;
  top: none;
  left: -5%;
  bottom: 0;
  width: 36%;
  /* transform: rotate(-90deg); */
}

@media (max-width: 500px) {
  .cardPage9 .sectionTree {
    border: none;
  }
  .cardPage9 .sectionTree .cardImage1 {
    width: 30%;
  }

  .cardPage9 .sectionTree .cardImage2 {
    width: 40%;
  }
}

.cardPage9 .sectionTree .send {
  background-color: #697444;
}

.cardPage9 #section6 .cardImage1 {
  position: absolute;
  left: 3px;
  top: 10px;
  width: 70px;
}


.cardPage9 .sectionSeven {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  border-color: #6974441d;
  border-radius: 0 0 0 50px;
}

.cardPage9 .sectionSeven .cardImage1 {
  position: absolute;
  left: none;
  right: 0px;
  bottom: none;
  top: 0px;
  width: 50%;
  transform: rotate(180deg);
}

.cardPage9 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #69744459;
  border-color: #697444;
  color: #323232;
}

.cardPage9 .sectionSeven .send {
  background-color: #697444;
}

.cardPage9 ._qWTcH {
  --fcc-background: #90a171 !important;
  --fcc-separator-color: #90a171 !important;
  --fcc-label-color: #90a171 !important;
}

.cardPage9 .portfolio #portfolio-flters li:hover,
.cardPage9 .portfolio #portfolio-flters li.filter-active {
  color: #90a171;
}

.cardPage9  .sectionOne .FamilyNames .damad {
  padding: 20px 10px 0 0;
}