.cardPage6 {
  background-image: url("../../../../public/asset/media/pic/cart6/cart6-1.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage6 .sidebarCart {
  background-image: none;
  background-color: #e1d0ad;
}

.cardPage6 .sidebarCardMobile {
  background-color: #e1d0ad;
}

.cardPage6 .sidebarCart li {
  color: rgb(70, 89, 62);
}

.cardPage6 .sectionOne {
  background-color: transparent;
  color: #fff;
  border: 4px solid #e1d0ad;
  background: rgb(70, 89, 62);
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px); */
}

@media (max-width: 500px) {
  .cardPage6 .sectionOne {
    border-radius: 0;
  }
}

.cardPage6 .sectionOne .cardImage1,
.cardPage6 .sectionOne .cardImage2 {
  display: none;
}

.cardPage6 #section1 .imgBox {
  display: flex;
  justify-content: center;
}

.cardPage6 #section1 .arosImage {
  width: 200px;
  height: 255px;
  border-radius: 100%;
  border: 5px #e1d0ad solid;
  margin: 25px 3px 0;
}

.cardPage6 #section1 .damadImage {
  width: 200px;
  height: 255px;
  border-radius: 100%;
  border: 5px #e1d0ad solid;
  margin: 25px 3px 0;
}

.cardPage6 .sectionOne .cardImage1 {
  width: 40%;
}

.cardPage6 .sectionOne .cardImage2 {
  width: 40%;
}

.cardPage6 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage6 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage6 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
}

.cardPage6 .sectionOne .boxDetail .detail1,
.cardPage6 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #cdcdcd;
  border-top: 1px solid #cdcdcd;
}
.cardPage6 .sectionOne .boxDetail .detail3 {
  margin-right: 8px;
}

/* .cardPage6 .sectionTwo .talar,
.cardPage6 .sectionTwo .address {
  color: #fff;
} */

.cardPage6 .sectionTree {
  background-image: none;
  background-color: transparent;
  color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
  border: 4px solid #e1d0ad;
  background: rgb(70, 89, 62);
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px); */
}

.cardPage6 .sectionTree textarea,
.cardPage6 .sectionTree input {
  background-color: transparent;
  color: #fff;
}

.cardPage6 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage6 .sectionTree .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
}

@media (max-width: 500px) {
  .cardPage6 .sectionTree .cardImage1 {
    width: 57%;
  }

  .cardPage6 .sectionTree .cardImage2 {
    width: 60%;
  }
}

.cardPage6 .sectionTree .send {
  background-color: #e1d0ad;
}

.cardPage6 .sectionSeven {
  background-image: none;
  background-color: transparent;
  color: #fff;
  overflow: hidden;
  width: 500px;
  border: 4px solid #e1d0ad;
  background: rgb(70, 89, 62);
  border-radius: 50%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.cardPage6 .sectionSeven .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage6 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
}

@media (max-width: 500px) {
  .cardPage6 .sectionSeven .cardImage1 {
    width: 57%;
  }

  .cardPage6 .sectionSeven .cardImage2 {
    width: 60%;
  }
}

.cardPage6 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #e1d0adaf;
  border-color: #e1d0ad;
}

.cardPage6 .sectionSeven .send {
  background-color: #e1d0ad;
}

.cardPage6 ._qWTcH {
  --fcc-background: rgb(70, 89, 62) !important;
  --fcc-separator-color: rgb(70, 89, 62) !important;
  --fcc-label-color: rgb(70, 89, 62) !important;
}

.cardPage6 .portfolio #portfolio-flters li:hover,
.cardPage6 .portfolio #portfolio-flters li.filter-active {
  color: #e1d0ad;
}
