.cardPage8 {
  background-image: url("../../../../public/asset/media/pic/cart8/cart8-1.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage8 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #6f2c41;
  color: #fff;
  padding: 20px;
  background-image: none;
}

.cardPage8 .sidebarCardMobile {
  background-color: #6f2c41;
  color: #fff;
}

.cardPage8 .sectionOne {
  background-color: #eee;
  padding: 60px 0px 100px;
}

.cardPage8 .sectionOne .arosImage {
  border-color: #6f2c41;
}

.cardPage8 .sectionOne .damadImage {
  border-color: #6f2c41;
}

.cardPage8 .sectionOne .cardImage1 {
  width: 60%;
  transform: rotate(-180deg);
}

.cardPage8 .sectionOne .cardImage2 {
  width: 60%;
}

.cardPage8 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage8 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage8 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
}

.cardPage8 .sectionOne .boxDetail .detail1,
.cardPage8 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #6f2c41;
  border-top: 1px solid #6f2c41;
}

.cardPage8 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
  background-image: url("../../../../public/asset/media/pic/cart8/cart8-1.png");
  box-shadow: 0 2px 16.8px 3.2px rgba(0, 0, 0, 0.08);
}

.cardPage8 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage8 .sectionTree .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 30%;
  transform: rotate(-180deg);
}

@media (max-width: 500px) {
  .cardPage8 .sectionTree .cardImage1 {
    width: 30%;
  }

  .cardPage8 .sectionTree .cardImage2 {
    width: 30%;
  }
}

.cardPage8 .sectionTree .send {
  background-color: #6f2c41;
}

.cardPage8 .sectionSeven {
  background-color: #fff;
  width: 500px;
  box-shadow: 0 2px 16.8px 3.2px rgba(0, 0, 0, 0.08);
}

.cardPage8 .sectionSeven .cardImage1 {
  position: absolute;
  left: -10px;
  bottom: -20px;
  width: 30%;
  transform: rotate(80deg);
}

/* .cardPage8 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 30%;
  transform: rotate(-180deg);
} */

@media (max-width: 500px) {
  .cardPage8 .sectionSeven .cardImage1 {
    width: 30%;
  }

  /* .cardPage8 .sectionSeven .cardImage2 {
    width: 30%;
  } */
}

.cardPage8 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #6f2c41;
  border-color: #6f2c41;
}

.cardPage8 .sectionSeven .send {
  background-color: #6f2c41;
}

.cardPage8 ._qWTcH {
  --fcc-background: #6f2c41 !important;
  --fcc-separator-color: #6f2c41 !important;
  --fcc-label-color: #6f2c41 !important;
}

.cardPage8 .portfolio #portfolio-flters li:hover,
.cardPage8 .portfolio #portfolio-flters li.filter-active {
  color: #6f2c41;
}
