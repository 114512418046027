/* CustomSelector.css */
.custom-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: Vazir !important;
}

select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.toggle-typing {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: Vazir !important;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Vazir !important;
}