.cardPage2 {
  background-color: #f7f7f7;
}

.cardPage2 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #fcc52d35;
  padding: 20px;
  background-image: url("../../../../public/asset/media/pic/cart2/flower2.svg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}

.cardPage2 .sidebarCardMobile {
  background-color: #ffd45c;
}

.cardPage2 .sectionOne {
  background-color: #fdfdfd;
}

.cardPage2 .sectionOne .arosImage {
  border-color: #eee6cc;
}

.cardPage2 .sectionOne .damadImage {
  border-color: #eee6cc;
}

.cardPage2 .sectionOne .cardImage1 {
  left: 0;
  top: 0;
  bottom: auto;
}

.cardPage2 .sectionOne .cardImage2 {
  width: 50%;
  position: absolute;
  top: auto;
  bottom: 0;
  right: 0;
}

.cardPage2 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage2 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage2 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
  border-right: 1px solid #ffd45c;
  border-left: 1px solid #ffd45c;
}

.cardPage2 .sectionOne .boxDetail .detail3 {
  margin-right: 8px;
}

.cardPage2 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
}

.cardPage2 .sectionTree .cardImage1 {
  position: absolute;
  right: auto;
  bottom: auto;
  top: -75px;
  left: 50%;
  width: 70%;
  transform: translateX(-50%);
}

.cardPage2 .sectionTree .cardImage2 {
  position: absolute;
  bottom: -70px;
  left: 50%;
  width: 70%;
  transform: translateX(-50%);
}

.cardPage2 .sectionTree .send {
  background-color: #ffd45c;
}

@media (max-width: 500px) {
  .cardPage2 .sectionTree .cardImage1 {
    width: 30%;
  }

  .cardPage2 .sectionTree .cardImage2 {
    width: 40%;
  }

  .cardPage2 .sectionTree {
    border: 1px solid #fcc52d;
  }
}

.cardPage2 #section6 .cardImage1 {
  position: absolute;
  left: 3px;
  top: 10px;
  width: 50px;
}

.cardPage2 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #fcc52db7;
  border-color: #fcc52d;
}

.cardPage2 .sectionSeven .send {
  background-color: #ffd45c;
}

.cardPage2 .sectionSeven {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  border: none;
}

.cardPage2 .sectionSeven .cardImage1 {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 30%;
}

/* .cardPage2 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
} */

@media (max-width: 500px) {
  .cardPage2 .sectionSeven .cardImage1 {
    width: 30%;
  }

  /* .cardPage2 .sectionSeven .cardImage2 {
    width: 40%;
  } */
}

.cardPage2 ._qWTcH {
  --fcc-background: #ffd45c !important;
  --fcc-separator-color: #ffd45c !important;
  --fcc-label-color: #ffd45c !important;
}

.cardPage2 .portfolio #portfolio-flters li:hover,
.cardPage2 .portfolio #portfolio-flters li.filter-active {
  color: #f9c128;
}

.cardPage2 .guestName {
  margin: 30px auto 0;
  padding: 0 20px 10px;
}
