.addressPage {
  width: 95%;
  max-width: 450px;
  height: 95vh;
  margin: 10px auto;
  background-color: #5c98b992;
  box-shadow: 0 2px 16.8px 3.2px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.addressPage .topFlowers {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -430px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.addressPage .names {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  margin-bottom: 30px;
}

.addressPage .names .aros {
  margin-bottom: 20px;
  font-family: IranNastaliq !important;
  font-size: 38px;
}

.addressPage .names .damad {
  margin-top: 20px;
  font-family: IranNastaliq !important;
  font-size: 38px;
}

.addressPage .names .and {
  margin: 0 15px;
  font-family: IranNastaliq !important;
}

.addressPage .talar {
  text-align: center;
  margin-bottom: 0;
}

.addressPage .address {
  text-align: center;
  margin-bottom: 30px !important;
}

.addressPage .choseText {
  margin-bottom: 15px !important;
}

.addressPage .apps a {
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.addressPage .apps a .app {
  width: 40px;
}

.addressPage .apps a .icon {
  width: 25px;
}

.addressPage .apps a span {
  color: #323232;
  font-size: 20px;
  margin-right: 10px;
}

.addressPage .links {
  display: flex;
  justify-content: center;
}

.addressPage .talar {
  font-size: 22px;
}

.addressPage .address {
  font-size: 20px;
}

/* .contactUsForm input{

} */

.contactUsForm input {
  border: 1px solid rgb(221, 221, 221);
  width: 100%;
  border-radius: 12px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.contactUsForm textarea {
  border: 1px solid rgb(221, 221, 221);
  width: 100%;
  border-radius: 12px;
  padding: 5px 10px;
  height: 120px;
}

.contactUsForm .submitBtn {
  display: block;
  padding: 10px 50px;
  border-radius: 30px;
  font-family: vazir !important;
  margin: 0 auto 25px 0;
  background-color: #9e9487;
  color: #fff;
  transition: all 0.3s;
}
