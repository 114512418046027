.cardPage7 {
  background-image: url("../../../../public/asset/media/pic/cart7/cart7-1.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage7 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #87ceeb;
  padding: 20px;
  background-image: none;
}

.cardPage7 .sidebarCart li {
  color: #fff !important;
}
.cardPage7 .sidebarCardMobile {
  background-color: #87ceeb;
  color: #fff;
}

.cardPage7 .sectionOne {
  background-color: #eee;
}

.cardPage7 .sectionOne .arosImage {
  border-color: #c8cfd6;
}

.cardPage7 .sectionOne .damadImage {
  border-color: #c8cfd6;
}

.cardPage7 .sectionOne .cardImage1 {
  width: 40%;
}

.cardPage7 .sectionOne .cardImage2 {
  width: 40%;
}

.cardPage7 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage7 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage7 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
}

.cardPage7 .sectionOne .boxDetail .detail1,
.cardPage7 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #87ceeb;
  border-top: 1px solid #87ceeb;
}

.cardPage7 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
}

.cardPage7 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage7 .sectionTree .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  /* transform: rotate(-90deg); */
}

@media (max-width: 500px) {
  .cardPage7 .sectionTree {
    border: none;
  }
  .cardPage7 .sectionTree .cardImage1 {
    width: 30%;
  }

  .cardPage7 .sectionTree .cardImage2 {
    width: 40%;
  }
}

.cardPage7 .sectionTree .send {
  background-color: #25365b;
}

.cardPage7 .sectionSeven {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  border-color: #25365b47;
}

.cardPage7 .sectionSeven .cardImage1 {
  position: absolute;
  left: -20px;
  bottom: -10px;
  width: 30%;
  transform: rotate(120deg);
}

/* .cardPage7 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  /* transform: rotate(-90deg);
} */

@media (max-width: 500px) {
  .cardPage7 .sectionSeven .cardImage1 {
    width: 30%;
  }

  /* .cardPage7 .sectionSeven .cardImage2 {
    width: 40%;
  } */
}

.cardPage7 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #25365bd6;
  border-color: #25365b;
}

.cardPage7 .sectionSeven .send {
  background-color: #25365b;
}

.cardPage7 ._qWTcH {
  --fcc-background: #87ceeb !important;
  --fcc-separator-color: #87ceeb !important;
  --fcc-label-color: #87ceeb !important;
}

.cardPage7 .portfolio #portfolio-flters li:hover,
.cardPage7 .portfolio #portfolio-flters li.filter-active {
  color: #87ceeb;
}
