.sidebarDashboard {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #30336b;
  color: #fff;
  padding: 20px 10px;
}

/* .sidebarDashboardMobile {
  display: none;
} */

.sidebarDashboard ul {
  list-style: none;
  padding: 0;
}

.sidebarDashboard li {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}

.sidebarDashboard li:hover {
  background-color: #c1c5ff70;
}

.sidebarDashboard ul li.active {
  background-color: #fff;
  color: #323232;
}

.sidebarDashboard li.showCard {
  border: 1px solid #fcfcfc;
}

.sidebarDashboard .showCard a {
  color: #fcfcfc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
}

.sidebarDashboard .showCard a img {
  width: 30px;
}

.sidebarDashboard .showCard a:hover {
  background-color: #716fd31c;
}

.dashboardPage {
  position: relative;
  min-height: 100vh;
}

.dashboardPage main {
  margin-right: 200px;
  padding: 20px;
}

.dashboardPage section {
  min-height: 100vh;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardPage .hambergerBtn {
  display: none;
}

@media (max-width: 500px) {
  .sidebarDashboard {
    display: none;
  }

  .dashboardPage main {
    margin-right: 0;
    padding: 50px 10px 10px;
  }

  .dashboardPage .hambergerBtn {
    display: block;
    border: 1px solid #30336b;
    padding: 8px 0px;
    background-color: #30336b;
    width: 54px;
    font-family: "inherit" !important;
    border-radius: 2px;
    z-index: 3;
    position: fixed;
    top: 10px;
    right: 10px;
    color: #fff;
  }

  /* .sidebarDashboardMobile {
    display: block;
    position: fixed;
    padding: 20px 10px;
    z-index: 3;
    border-radius: 5px 5px 0 0;
  }

  .sidebarDashboardMobile ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .sidebarDashboardMobile li {
    padding: 10px;
    border-radius: 10px;
    font-family: vazir;
  }
  .sidebarDashboardMobile .showCard a{
    color: #fff;
  } */
}

/* sidebar  */
.sidebarDashboardMobile {
  position: fixed;
  top: 0;
  right: -255px;
  width: 250px;
  height: 100%;
  background-color: #30336b;
  color: #fff;
  transition: right 0.3s;
  z-index: 5;
  padding: 40px 20px 10px;
}

.sidebarDashboardMobile nav {
  padding-top: 35px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.sidebarDashboardMobile.open {
  right: -2px;
}

.sidebarDashboardMobile .close {
  position: absolute;
  left: 15px;
  top: 20px;
  /* border: 1px solid #d9d9d9; */
  border-radius: 50%;
  width: 30px;
  padding: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #fff;
  font-size: 30px;
}

.sidebarDashboardMobile .close img {
  width: 100%;
}

.sidebarDashboardMobile ul li {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 10px;
}

.sidebarDashboardMobile ul li a {
  text-decoration: none;
  display: block;
  color: #323232;
  transition: all 0.3s;
}

.sidebarDashboardMobile ul li a.active {
  color: #aca299;
}

.sidebarDashboardMobile ul li a:hover {
  color: #f0d7beda;
}

.sidebarDashboardMobile ul li.active {
  background-color: #fff;
  color: #323232;
}

.sidebarDashboardMobile li.showCard {
  border: 1px solid #fcfcfc;
}

.sidebarDashboardMobile .showCard a {
  color: #fcfcfc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
}

.sidebarDashboardMobile .showCard a img {
  width: 30px;
}

/* --------------  section 1 ----------------  */
.dashboardPage .sectionOne {
  width: 700px;
  min-height: 700px;
  margin: 0 auto;
  text-align: center;
  font-family: ShekastehHellolove;
}

.CardsData {
  width: 60px;
  margin: 0 10px;
}

tr {
  border-bottom: 1px solid #30336b;
}

@media (max-width: 500px) {
  .CardsData {
    width: 40px;
    margin: 5px 0 5px 0;
  }
}

.chageBtnComment {
  position: fixed;
  left: 10px;
  bottom: 20px;
}

.square {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(68, 68, 68);
  display: inline-block;
  margin: 0 5px;
}

/* --------------  section 2 ----------------  */
.dashboardPage .sectionTwo {
  width: 900px;
  margin: 50px auto 0;
}

/* --------------  section 3 ----------------  */
.dashboardPage .sectionTree {
  width: 700px;
  min-height: 700px;
  margin: 0 auto;
  text-align: center;
  font-family: ShekastehHellolove;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboardPage .sectionTree .text {
  max-width: 45%;
  margin: 15px auto;
}

.dashboardPage .formInformation {
  max-width: 900px;
  margin: 0 auto 50px;
  background-color: #5b66ff0a;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  /* overflow: hidden; */
}

.formDisable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.14);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #434343;
  font-size: 30px;
  z-index: 1;
}

.licenseFree {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #e74c3c;
  z-index: 4;
  font-family: Vazir !important;
  transform: rotate(25deg);
}

.licenseDisable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.14);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #434343;
  z-index: 1;
  transition: all 0.3s;
}

.licenseDisable:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.1px);
  -webkit-backdrop-filter: blur(2.1px);
  border: 1px solid #e74c3c;
}

.licenseDisable .licenseText {
  opacity: 0;
  transition: all 0.3s;
  font-size: 15px;
  color: #273c75;
}

.licenseDisable:hover .licenseText {
  opacity: 1;
}

.licenseDisable .licenseLink {
  opacity: 0;
  transition: all 0.3s;
  font-size: 15px;
  color: #e74c3c;
  border: 1px solid #e74c3c;
  padding: 6px 15px;
  border-radius: 4px;
  font-family: Vazir !important;
}

.licenseDisable:hover .licenseLink {
  opacity: 1;
}

.licenseDisable .imageUpdate {
  width: 30px;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.3s;
}

.licenseDisable:hover .imageUpdate {
  opacity: 1;
}
.dashboardPage .formInformation label {
  margin-bottom: 7px;
}

.dashboardPage .saveInformation {
  padding: 8px 25px;
  border-radius: 5px;
  background-color: #474787;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.dashboardPage .saveInformation:hover {
  background-color: #706fd3;
  transform: translateY(-3px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dashboardPage .formInformation .radio-label {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.dashboardPage .formInformation .radio-label label {
  width: 70px;
  height: 30px;
  border: 1px solid #979797;
  font-size: 14px;
  line-height: 28px;
  margin: 0 5px;
  display: block;
  cursor: pointer;
}

.dashboardPage
  .formInformation
  .radio-label
  input[type="radio"]:checked
  ~ label {
  background: #f8a5c29c;
  border: 1px solid #f8a5c2;
  color: #fff;
}

.dashboardPage .templateSelect {
  border: 2px solid #5b66ff0a;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.dashboardPage .templateSelected {
  border: 2px solid rgb(210, 47, 47);
  border-radius: 5px;
  overflow: hidden;
}

.dashboardPage .labelTemplate {
  margin-right: 0px;
  width: 160px;
  position: relative;
}

.dashboardPage .labelTemplate img {
  width: 150px;
}

.cartPageOption {
  position: sticky;
  left: 0px;
  bottom: 10px;
  width: 115px;
  margin-right: auto;
  z-index: 2;
}

 .support {
  margin-bottom: 5px;
  margin-left: 5px;
}

 .support a {
  color: #474787;
  background-color: #716fd307;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s;
  padding: 2px 8px;
}

 .support a:hover {
  background-color: #716fd31c;
}

 .support a img {
  width: 50px;
}

/* .cartPageOption .showCard a{
  color: #474787;
  background-color: #716fd307;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  transition: all .3s;
}
.cartPageOption .showCard a img{
  width: 50px;
}
.cartPageOption .showCard a:hover{
  background-color: #716fd31c;
} */
@media (max-width: 500px) {
  .cartPageOption {
    display: flex;
    bottom: 20px;
    /* bottom: disable; */
    left: 8px;
    width: 56px;
  }

  .cartPageOption span {
    display: none;
  }

   .support a {
    background-color: #d1d1f8;
    padding: 0;
  }

  /* .cartPageOption .showCard a{
    background-color: #d1d1f8;
  } */
}

/* --------------  section 5 ----------------  */

.image-gallery {
  text-align: center;
  padding: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  background-color: #f5f5f5;
  border: none;
  padding: 8px 16px;
  margin: 0 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.filter-buttons button:hover {
  background-color: #ddd;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gallery-item {
  width: calc(33.33% - 20px);
  height: calc(33.33% - 20px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-item.visible {
  opacity: 1;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: 7px !important;
  font-family: Vazir !important;
  right: 20px;
  left: auto;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  right: 10px;
  left: auto;
  font-family: Vazir !important;
}

.sidebarDashboard .logOut,
.sidebarDashboardMobile .logOut {
  background-color: #4a69bd;
  padding: 8px 30px;
  border-radius: 19px;
  color: #fff;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: vazir !important;
  width: 100%;
  margin-top: 30px;
}

.formInformation .appRout {
  width: 30px;
  margin-left: 5px;
}

.css-1nmdiq5-menu{
  white-space: pre-wrap;
}