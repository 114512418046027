.aiPagewapper {
  background-image: url("../../../public/asset/media/image/background-ai.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  padding-top: 100px;
}

.aiPage {
  max-width: 900px;
  /* border-radius: 15px; */
  width: 95%;
  min-height: 500px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0 auto;
  padding: 40px 20px 20px;
  position: relative;
  overflow: hidden;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* .aiPage:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url('/public/asset/media/image/backgroundAI.webp');
    background-size: cover;
    background-repeat: no-repeat;
  } */

.aiPage .flowerLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 600px;
  max-width: 100%;
  z-index: 2;
}

.aiPage .flowerRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 530px;
  max-width: 100%;
  z-index: 2;
}

.aiPage .flowerBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.aiPage .disable {
  color: rgb(200, 200, 200);
  text-decoration: line-through;
}

.aiPage .addToCart {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 30px;
  /* border: 1px solid #c2c2c2; */
  background-color: #a43043;
  color: rgb(240, 248, 255);
  transition: all 0.3s;
  position: relative;
  z-index: 2;
}

.aiPage .addToCart:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-5px);
  border: none;
}

.aiPage .cost {
  font-size: 33px;
  line-height: 38px;
  text-decoration: line-through;
  color: #e1e1e1;
  margin-bottom: 12px !important;
}

.aiPage .off {
  font-size: 38px;
  line-height: 40px;
  color: #323232;
  margin-bottom: 20px !important;
}

.aiPage .arrowBtns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.aiPage .arrowBtn {
  background: linear-gradient(180deg, #f0ede8 0%, #ddd4c3 100%);
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 8px;
}

/* QuestionAi.css */
.aiPage .slide-enter {
  opacity: 0;
  transform: translateY(100%);
}
.aiPage .slide-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}
.aiPage .slide-exit {
  opacity: 1;
  transform: translateY(0);
}
.aiPage .slide-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms, transform 500ms;
}
