.cardPage10 {
  /* background-image: url("../../../../public/asset/media/pic/cart9/cart9-3.png"); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cardPage10 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #bab694;
  padding: 20px;
  background-image: none;
}

.cardPage10 .sidebarCart li {
  color: #fff !important;
}
.cardPage10 .sidebarCardMobile {
  background-color: #bab694;
  color: #fff;
}

.cardPage10 .sectionOne {
  background-color: #eee;
  border-radius: 30px;
}

.cardPage10 .sectionOne .arosImage {
  border-color: #bab694;
}

.cardPage10 .sectionOne .damadImage {
  border-color: #bab694;
}

.cardPage10 .sectionOne .cardImage1 {
  width: 100%;
}

.cardPage10 .sectionOne .cardImage2 {
  width: 100%;
}

.cardPage10 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage10 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage10 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
}

.cardPage10 .sectionOne .boxDetail .detail1,
.cardPage10 .sectionOne .boxDetail .detail3 {
  border-bottom: 1px solid #bab694;
  border-top: 1px solid #bab694;
}

.cardPage10 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
  border-radius: 50px 0 50px 0;
}

.cardPage10 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: none;
  top: 0;
  width: 30%;
  /* transform: rotate(-90deg); */
}

.cardPage10 .sectionTree .cardImage2 {
  position: absolute;
  top: none;
  left: -5%;
  bottom: 0;
  width: 60%;
}

@media (max-width: 500px) {
  .cardPage10 .sectionTree {
    border: none;
  }
}

.cardPage10 .sectionTree .send {
  background-color: #aaa57e;
}

.cardPage10 #section6 .cardImage1 {
  position: absolute;
  left: 3px;
  top: 10px;
  width: 70px;
}


.cardPage10 .sectionSeven {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  border-color: #aaa57e;
  border-radius: 0 0 0 50px;
}

.cardPage10 .sectionSeven .cardImage1 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 50%;
  /* transform: rotate(180deg); */
}

.cardPage10 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #aaa57e76;
  border-color: #aaa57e;
  color: #323232;
}

.cardPage10 .sectionSeven .send {
  background-color: #aaa57e;
}

.cardPage10 ._qWTcH {
  --fcc-background: #bab694 !important;
  --fcc-separator-color: #bab694 !important;
  --fcc-label-color: #bab694 !important;
}

.cardPage10 .portfolio #portfolio-flters li:hover,
.cardPage10 .portfolio #portfolio-flters li.filter-active {
  color: #bab694;
}

.cardPage10  .sectionOne .FamilyNames .damad {
  padding: 20px 10px 0 0;
}