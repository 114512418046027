.sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
  background-image: url("../../../../public/asset/media/pic/cart1/side-nav.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

.sidebarCart ul {
  list-style: none;
  padding: 0;
}

.sidebarCart li {
  margin-bottom: 10px;
  cursor: pointer;
}

.cardPage {
  position: relative;
}

.cardPage main {
  /* margin-right: 200px; */
  margin-right: 80px;
  padding: 10px;
}

.cardPage section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 30px 0;
}

.sidebarCardMobile {
  display: none;
}

@media (max-width: 500px) {
  .sidebarCart {
    display: none;
  }

  .cardPage main {
    margin-right: 0;
    padding-bottom: 50px;
  }

  .sidebarCardMobile {
    display: block;
    position: fixed;
    bottom: -3px;
    right: 0;
    height: 67px;
    width: 100%;
    background-color: #c5a1ae;
    color: #fff;
    padding: 0 10px;
    z-index: 3;
  }

  .sidebarCardMobile ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6px;
  }

  .sidebarCardMobile li {
    padding: 10px;
    border-radius: 10px;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* --------------  section 1 ----------------  */
.sectionOne {
  width: 600px;
  max-width: 100%;
  min-height: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 70px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .sectionOne {
    box-shadow: none;
  }
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  z-index: 0;
}

.sectionOne .names {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.sectionOne .names .aros {
  margin-bottom: 20px;
  font-family: IranNastaliq !important;
  font-size: 38px;
  padding: 10px;
}

.sectionOne .names .damad {
  margin-top: 20px;
  font-family: IranNastaliq !important;
  font-size: 38px;
  padding: 10px;
}

.sectionOne .names .and {
  margin: 0 4px;
  font-family: IranNastaliq !important;
  font-size: 20px;
}

.sectionOne .FamilyNames {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
}

.sectionOne .FamilyNames .aros {
  font-family: IranNastaliq !important;
  font-size: 25px;
  padding: 10px;
}

.sectionOne .FamilyNames .damad {
  font-family: IranNastaliq !important;
  font-size: 25px;
  padding: 10px;
}

.sectionOne .FamilyNames .and {
  margin: 0 3px;
  font-family: IranNastaliq !important;
  font-size: 20px;
}

.sectionOne .namesEng {
  /* font-family: Parisienne !important; */
  /* font-weight: bold; */
}

.sectionOne .namesEng .names .aros {
  font-family: Parisienne !important;
  font-weight: bold;
  font-size: 40px;
}

.sectionOne .namesEng .names .damad {
  margin-top: 20px;
  font-weight: bold;
  font-family: Parisienne !important;
  font-size: 40px;
}

.sectionOne .namesEng .names .and {
  margin: 0 15px;
  font-weight: bold;
  font-family: Parisienne !important;
}

.sectionOne .namesEng .FamilyNames .aros {
  font-family: Parisienne !important;
  font-size: 30px;
}

.sectionOne .namesEng .FamilyNames .damad {
  font-family: Parisienne !important;
  font-size: 30px;
}

.sectionOne .namesEng .FamilyNames .and {
  margin: 0 10px;
  font-family: Parisienne !important;
}

.sectionOne .text {
  max-width: 45%;
  margin: 15px auto;
  font-family: IranNastaliq !important;
  word-spacing: 1.5px;
  white-space: pre-wrap;
  line-height: 45px;
  font-size: 20px;
}
.sectionOne .text.center {
text-align: center;
}

.sectionOne .text.right {
  text-align: right;
  }

.sectionOne .imgBox {
  display: flex;
  justify-content: center;
}

.sectionOne .arosImage {
  width: 200px;
  height: 255px;
  border-radius: 100%;
  border: 5px #fcf0e2 solid;
  margin: 25px 3px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionOne .damadImage {
  width: 200px;
  height: 255px;
  border-radius: 100%;
  border: 5px #fcf0e2 solid;
  margin: 25px 3px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionOne .detail {
  margin-bottom: 7px;
  font-family: IranNastaliq !important;
  word-spacing: 1.5px;
  font-size: 18px;
}

.sectionOne .cardImage1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  /* z-index: -1; */
}

.sectionOne .cardImage2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  /* z-index: -1; */
}

@media (max-width: 500px) {
  .sectionOne{
    padding: 60px 0 100px;
  }
  .sectionOne .arosImage {
    width: 40%;
    height: 180px;
    margin-top: 55px;
    border-width: 8px;
    border-width: 4px;
  }

  .sectionOne .damadImage {
    width: 40%;
    height: 180px;
    margin-top: 55px;
    border-width: 8px;
    border-width: 4px;
  }

  .sectionOne .text {
    max-width: 90%;
    margin: 25px auto;
  }

  .sectionOne .cardImage1 {
    width: 85%;
  }

  .sectionOne .cardImage2 {
    width: 60%;
  }
}

/* --------------  section 2 ----------------  */
.sectionTwo {
  width: 900px;
  max-width: 100%;
  margin: 50px auto 40px;
}

.sectionTwo .talar {
  text-align: center;
  margin-bottom: 15px;
  color: #323232;
  font-family: IranNastaliq !important;
}

.sectionTwo .address {
  text-align: center;
  font-family: IranNastaliq !important;
  margin-bottom: 25px !important;
  word-spacing: 2px;
  font-size: 18px;
}

.sectionTwo .apps {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.sectionTwo .apps .app {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

/* --------------  section 3 ----------------  */
.sectionTree {
  background-image: url("../../../../public/asset/media/pic/cart1/cart1.png");
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 100%;
  width: 700px;
  min-height: 700px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sectionTree .cardImageN1,
.sectionTree .cardImageN2 {
  display: none;
  z-index: 0;
}

@media (max-width: 500px) {
  .sectionTree {
    background-image: none;
    border: 1px solid #c5a1ae;
    min-height: 380px;
  }

  .sectionTree .cardImageN1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: rotate(180deg);
  }

  .sectionTree .cardImageN2 {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.sectionTree .title {
  font-family: IranNastaliq !important;
  margin-bottom: 20px;
}
.sectionTree textarea {
  /* font-family: IranNastaliq !important; */
  height: 130px;
}

.sectionTree textarea::placeholder {
  /* font-family: IranNastaliq !important; */
}

.sectionTree input {
  border: 1px solid #ccc;
  margin-bottom: 8px;
  width: 100%;
  border-radius: 0px;
  /* font-family: IranNastaliq !important; */
}

.sectionTree input::placeholder {
  /* font-family: IranNastaliq !important; */
}

.sectionTree .send {
  background-color: #c5a1ae;
  padding: 8px 40px;
  color: #fff;
  position: relative;
  z-index: 1;
  margin-top: 8px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.sectionTree .send:active {
  transform: scale(1.3);
  opacity: .3;
}

.sectionTree .send:disabled {
  background-color: #7f7f7fbb;
  opacity: .3;
}

/* --------------  section 4 ----------------  */
#section4 {
  padding: 20px 0 0;
}

@media (max-width: 600px) {
  ._qWTcH {
    --fcc-digit-block-width: 35px !important;
    font-size: 40px !important;
    --fcc-digit-block-height: 47px;
  }
}

._qWTcH {
  --fcc-background: #c5a1ae !important;
  --fcc-separator-color: #c5a1ae !important;
  --fcc-label-color: #c5a1ae !important;
  font-family: inherit !important;
}

/* ._qWTcH {
    --fcc-flip-duration: 0.7s;
    --fcc-digit-block-width: 46px;
    --fcc-digit-block-height: 47px;
    --fcc-digit-font-size: 67px;
    --fcc-label-font-size: 23px;
    --fcc-label-color: #fff;
    --fcc-background: #0f181a;
    --fcc-digit-color: #fff;
    --fcc-divider-color: hsla(0,0%,100%,0.4);
    --fcc-divider-height: 1px;
    --fcc-shadow: 0 0 2px 1px rgba(0,0,0,0.1);
    --fcc-separator-size: 5px;
    --fcc-separator-color: var(--fcc-digit-color);
    font-family: inherit;
    font-size: var(--fcc-digit-font-size);
    color: var(--fcc-digit-color);
    line-height: 0;
    font-weight: 500;
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
    display: flex;
    align-items: center;
} */

/* --------------  section 5 ----------------  */

.image-gallery {
  text-align: center;
  padding: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-buttons button {
  background-color: #f5f5f5;
  border: none;
  padding: 8px 16px;
  margin: 0 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.filter-buttons button:hover {
  background-color: #ddd;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gallery-item {
  width: calc(33.33% - 20px);
  height: calc(33.33% - 20px);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.gallery-item.visible {
  opacity: 1;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.gallery-item img:hover {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio {
  width: 100%;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .portfolio .portfolio-item {
    padding: 0;
  }
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: #fff;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: #f8a5c29c;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(69, 80, 91, 0.8);
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #d7dce1;
  border-left: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #d7dce1;
  border-right: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #45505b;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #45505b;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #45505b;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #148af9;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

.portfolio-details {
  padding-top: 40px;
}

.portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid var(--color-orange);
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: var(--color-orange);
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(69, 80, 91, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

.portfolio-item {
  transition: all 0.3s ease-in-out;
}

.portfolio-item.filter-app {
  left: 0;
  top: 0;
}

/* --------------  section 3 ----------------  */
.sectionSeven {
  max-width: 100%;
  width: 700px;
  min-height: 300px;
  margin: 0 auto 8px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c5a1ae;
  overflow: hidden;
}

.sectionSeven .cardImageN1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: rotate(180deg);
  z-index: 0;
}

.sectionSeven .cardImageN2 {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

@media (min-width: 500px) {
  .sectionSeven .cardImageN1 {
    top: -50px;
  }

  .sectionSeven .cardImageN2 {
    bottom: -50px;
  }
}

.sectionSeven .title {
  font-family: IranNastaliq !important;
  margin-bottom: 20px;
}

.sectionSeven .radio-label {
  max-width: 360px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0 50px;
  font-family: IranNastaliq !important;
}

.sectionSeven .radio-label label {
  width: 70px;
  height: 30px;
  border: 1px solid #979797;
  font-size: 14px;
  line-height: 28px;
  margin: 0 5px 10px;
  display: block;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #c5a1aec6;
  border: 1px solid #c5a1ae;
  color: #fff;
}

.sectionSeven .send {
  margin-top: 25px;
  background-color: #c5a1ae;
  padding: 8px 40px;
  color: #fff;
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.sectionSeven .send:active {
  transform: scale(1.3);
  opacity: .3;
}


.guestName {
  text-align: center;
  color: #323232;
  font-family: IranNastaliq !important;
  font-size: 25px;
  margin: 30px auto 15px;
  border-bottom: 1px solid #323232;
  width: fit-content;
  padding: 0 20px 10px;
}

/* timeline  */
.timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;
}

/* خط عمودی وسط */
.timeline-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #ccc;
  transform: translateX(-50%);
}

/* هر آیتم تایم‌لاین */
.timeline-event {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 40px;
  position: relative;
}

/* آیتم‌های سمت چپ */
.timeline-event.left {
  flex-direction: row;
  margin-left: 0;
  margin-right: auto;
  text-align: right;
}

/* آیتم‌های سمت راست */
.timeline-event.right {
  flex-direction: row-reverse;
  margin-left: auto;
  margin-right: 0;
  text-align: left;
}

/* ایکون */
.timeline-icon {
  font-size: 24px;
  background-color: #ff6f61;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

/* محتوای هر آیتم */
.timeline-content {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
}

/* خط افقی متصل به خط عمودی */
.timeline-connector {
  position: absolute;
  top: 50%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
  z-index: 0;
}

/* تنظیمات خط افقی برای آیتم‌های سمت چپ */
.timeline-event.left .timeline-connector {
  right: 0;
  transform: translateY(-50%);
}

/* تنظیمات خط افقی برای آیتم‌های سمت راست */
.timeline-event.right .timeline-connector {
  left: 0;
  transform: translateY(-50%);
}