.navBar {
  width: 100%;
  height: 4.5rem;
  background-color: #ede9fe;
  display: grid;
  grid-template-columns: 1fr 150px 1fr;
  gap: 24px;
  align-items: center;
  padding: 0 40px;
}

.navBar nav {
  display: flex;
  justify-content: start;
}

.navBar nav ul {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 0;
}

.navBar nav ul li {
  margin: 0 1rem;
}

.navBar nav ul li a {
  text-decoration: none;
  display: block;
  color: #323232;
  transition: all 0.3s;
}

.navBar nav ul li a.active {
  color: #f0d7be;
}

.navBar nav ul li a:hover {
  color: #f0d7beda;
}

.activeBasket {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

.navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 1000;
  transition: height 0.3s, background-color 0.3s, opacity 0.3s;
}

.navBar.scroll {
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
}

.navBar .logo {
}

.navBar .logo a {
  font-family: Parisienne;
  font-size: 32px;
  text-decoration: none;
  color: goldenrod;
  transition: all 0.3s;
  display: block;
}

.navBar .logo a:hover {
  transform: scale(1.1);
}

.navBar .loginButton {
  display: flex;
  justify-content: end;
}

.navBar .loginButton a {
  background-color: #fff5f0;
  padding: 8px 30px;
  border-radius: 19px;
  color: #323232;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: vazir !important;
}

.navBar .leftSide {
  text-align: left;
}

.navBar .hambergerBtn {
  display: none;
}

.navBar .accountIcon {
  width: 50px;
}

.navBar .logOut {
  background-color: #fff5f0;
  padding: 8px 30px;
  border-radius: 19px;
  color: #323232;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: vazir !important;
  margin-left: 20px;
}

@media (max-width: 500px) {
  .navBar {
    grid-template-columns: 150px 55px;
    padding: 0 15px;
    justify-content: space-between;
  }

  .navBar nav {
    display: none;
  }

  .navBar .hambergerBtn {
    display: inline-block;
    border: 1px solid rgb(201, 201, 201);
    padding: 8px 0px;
    background-color: transparent;
    width: 54px;
    font-family: "inherit" !important;
    border-radius: 2px;
  }
  .navBar .loginButton {
    display: none;
  }
  .navBar .leftSide {
    display: none;
  }
}

/* sidebar  */
.sidebar {
  position: fixed;
  top: 0;
  right: -250px;
  width: 250px;
  height: 100%;
  background-color: #f7f2ef;
  color: #fff;
  transition: right 0.3s;
  z-index: 5;
  padding: 100px 20px 10px;
}

.sidebar nav {
  padding-top: 35px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.sidebar.open {
  right: 0;
}

.sidebar .close {
  position: absolute;
  left: 15px;
  top: 95px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 30px;
  padding: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.sidebar .close img {
  width: 100%;
}

.sidebar nav ul li {
  margin-bottom: 15px;
}

.sidebar nav ul li a {
  text-decoration: none;
  display: block;
  color: #323232;
  transition: all 0.3s;
}

.sidebar nav ul li a.active {
  color: #aca299;
}

.sidebar nav ul li a:hover {
  color: #f0d7beda;
}

.sidebar .loginButton {
  display: flex;
}

.sidebar .loginButton a {
  padding: 8px 30px;
  border-radius: 5px;
  color: #323232;
  text-decoration: none;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  font-family: vazir !important;
}
