.cardPage3 {
  background-color: #c90022;
}

.cardPage3 .sidebarCart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
  background-image: url("../../../../public/asset/media/pic/cart3/cart3-2.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cardPage3 .sidebarCardMobile {
  background-color: #77011b;
}

.cardPage3 .sectionOne {
  background-color: #eee;
}

.cardPage3 .sectionOne .arosImage {
  border-color: #b8262b;
}

.cardPage3 .sectionOne .damadImage {
  border-color: #b8262b;
}

.cardPage3 .sectionOne .cardImage1 {
  width: 35%;
}

.cardPage3 .sectionOne .cardImage2 {
  width: 40%;
}

.cardPage3 .sectionOne .boxDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.cardPage3 .sectionOne .boxDetail .detail {
  min-width: 70px;
}

.cardPage3 .sectionOne .boxDetail .detail2 {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  justify-content: space-between;
  font-size: 1.3rem;
  border-right: 1px solid #951313;
  border-left: 1px solid #951313;
}
.cardPage3 .sectionOne .boxDetail .detail3 {
  margin-right: 8px;
}

.cardPage3 .sectionTree {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  min-height: 400px;
}

.cardPage3 .sectionTree .cardImage1 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 30%;
}

.cardPage3 .sectionTree .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
}

.cardPage3 .sectionTree .send {
  background-color: #9b001e;
}

@media (max-width: 500px) {
  .cardPage3 .sectionTree .cardImage1 {
    width: 30%;
  }

  .cardPage3 .sectionTree .cardImage2 {
    width: 40%;
  }
}

.cardPage3 .sectionSeven .radio-label input[type="radio"]:checked ~ label {
  background: #980e0ea2;
  border-color: #9c1111;
}

.cardPage3 .sectionSeven .send {
  background-color: #9b001e;
}
.cardPage3 .sectionSeven {
  background-image: none;
  background-color: #fff;
  overflow: hidden;
  width: 500px;
  border: none;
}

.cardPage3 .sectionSeven .cardImage1 {
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 30%;
  transform: rotate(90deg);
}

/* .cardPage3 .sectionSeven .cardImage2 {
  position: absolute;
  top: 0;
  left: -5%;
  width: 40%;
  transform: rotate(-90deg);
} */

@media (max-width: 500px) {
  .cardPage3 .sectionSeven .cardImage1 {
    width: 30%;
  }

  /* .cardPage3 .sectionSeven .cardImage2 {
    width: 40%;
  } */
}

.cardPage3 ._qWTcH {
  --fcc-background: #77011b !important;
  --fcc-separator-color: #77011b !important;
  --fcc-label-color: #77011b !important;
}

.cardPage3 .portfolio #portfolio-flters li:hover,
.cardPage3 .portfolio #portfolio-flters li.filter-active {
  color: #ff4431;
}

.cardPage3 .guestName {
  color: #ffffff;
  margin: 30px auto 0;
  border-bottom: 1px solid #fff;
  padding: 0 20px 10px;
}
