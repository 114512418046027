.App {
  text-align: center;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1 {
  font-size: 52px;
  line-height: 62px;
  font-family: Vazir !important;
}

h2 {
  font-size: 24px;
  font-family: Vazir !important;
}

h3 {
  font-size: 22px;
  line-height: 25px;
  font-family: Vazir !important;
}

h4 {
  font-size: 20px;
  line-height: 25px;
  font-family: Vazir !important;
}

h5 {
  font-size: 18px;
  line-height: 29px;
  font-family: Vazir !important;
}

h6 {
  font-family: Vazir !important;
  font-size: 16px;
  line-height: 25px;
}

p {
  font-family: Vazir !important;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0 !important;
}

a {
  color: #323232;
}

a,
a:hover,
a:visited {
  outline: none !important;
  text-decoration: none !important;
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  color: #2e58ad;
}

a:not([href]) {
  color: initial;
}

dl,
ol,
ul {
  margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.focus,
button:focus {
  outline: none;
  outline: 0;
  outline-color: initial;
  outline-style: none;
  outline-width: 0px;
  box-shadow: none !important;
}

button {
  border: none;
  font-size: 16px;
  color: #323232;
  font-family: Vazir !important;
}

input:focus {
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #444444 !important;
}

textarea {
  resize: none !important;
  padding: 8px;
  border: 1px solid #ccc;
  font-family: Vazir;
  width: 100%;
}

textarea:focus {
  outline: none;
}

textarea::placeholder {
  font-size: 14px;
  color: #969696;
  font-family: Vazir;
}

input::placeholder {
  font-size: 12px;
  line-height: 20px;
  font-family: Vazir;
  color: #969696;
}

input[type="text"],
input[type="address"],
input[type="password"],
input[type="number"],
input[type="email"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Vazir;
  width: 100%;
}

body {
  background: #fff;
  color: #585858;
  direction: rtl;
  font-size: 16px;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  font-family: Vazir !important;
  z-index: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  line-height: normal;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

input:disabled {
  background-color: rgba(239, 239, 239, 0.69);
  color: rgb(170, 170, 170);
  border-color: rgba(118, 118, 118, 0.16);
}

/* -------------- home page => blog ------------ */
.whyUs {
  background-image: url("../public/asset/media/pic/flower-background3.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: center;
  min-height: 550px;
}

@media (max-width: 500px) {
  .whyUs {
    padding: 80px 18px 20px;
    background-size: 200%;
    background-color: #f7f2ef;
  }
}

/* -------------- home page => plan ------------ */
.planList {
  background-image: url("../public/asset/media/pic/flower-background2.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-wrap: wrap;
  padding: 180px 0 80px;
  overflow: hidden;
}

@media (max-width: 500px) {
  .planList {
    background-size: contain;
  }
}

.planList .plan {
  width: 290px;
  height: 400px;
  margin: 10px 1rem;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.planList .plan .imageBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.planList .plan .content {
  width: 230px;
  height: 340px;
  z-index: 1;
  padding: 20px;
  background-color: rgba(233, 233, 233, 0.8);
  position: absolute;
  bottom: 0%;
  left: 0;
  transition: all 0.5s;
  border-top-right-radius: 20px;
  text-align: center;
  color: #323232;
  padding-top: 40px;
}

.planList .plan:hover .content {
  width: 100%;
  height: 100%;
  border-top-right-radius: 0;
  padding-top: 20px;
}

.planList .plan .title {
  margin-bottom: 20px;
}

.planList .plan .cost {
  text-decoration: line-through;
  color: #969696;
  font-size: 14px;
}

.planList .plan .offer {
  margin-bottom: 25px;
  color: brown;
}

.planList .plan .details {
  text-align: right;
}

.planList .plan .more {
  background: linear-gradient(180deg, #f0ede8 0%, #ddd4c3 100%);
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 8px;
  transition: all 0.3s;
}

.planList .plan:hover .more {
  animation: dropdown 0.3s ease-in-out;
}

@keyframes dropdown {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.planList .plan .more:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* -------------- home page => option ------------ */
.optionListSection {
  background-image: url("../public/asset/media/pic/flower-background.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 0;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionListSection .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0;
}

.optionList .option {
  width: 190px;
  height: 200px;
  background-color: rgb(255, 255, 255);
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.18);
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  margin: 10px;
}

.optionList .option:hover {
}

.optionList .option .image {
  margin-bottom: 15px;
  transition: all 0.3s;
}

.optionList .option:hover .image {
  transform: rotate(10deg);
}

.optionList .option .title {
  margin-bottom: 8px;
}

/* -------------- home page => banner ------------ */
.banner {
  background-color: #fff5f0;
  position: relative;
  min-height: 800px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 80px);
}

.banner .flowerLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 600px;
  max-width: 100%;
  z-index: 2;
}

.banner .flowerRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 530px;
  max-width: 100%;
  z-index: 2;
}

.banner .flowerBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.banner .aroosiOnline {
  font-size: 200px;
  line-height: 150px;
  color: #ebebeb;
  position: absolute;
  left: 2%;
  bottom: 0;
  opacity: 0.5;
  font-family: Parisienne !important;
  z-index: 0;
}

.banner .bride {
  max-width: 300px;
}

.typed-cursor {
  font-size: 40px;
}

.showExampleCard {
  position: relative;
  display: inline-block;
  z-index: 2;
  text-decoration: none;
  color: #323232;
  padding: 11px 20px;
  border: 1px solid #ccc;
  margin-top: 10px;
  transition: all 0.3s;
}

.showExampleCard:hover {
  color: #323232;
  padding: 11px 20px;
  border-color: rgba(240, 229, 205, 0.609);
  background-color: rgba(240, 229, 205, 0.609);
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.freeCard {
  position: relative;
  display: inline-block;
  z-index: 2;
  text-decoration: none;
  color: #ee5253;
  padding: 11px 20px;
  border: 1px solid #ff6b6b;
  margin-top: 10px;
  margin-right: 5px;
  background-color: transparent;
  transition: all 0.3s;
}

.freeCard:hover {
  color: #fff;
  padding: 11px 20px;
  border-color: #ff6b6b;
  background-color: #ff6b6b;
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

@media (max-width: 500px) {
  .banner .flowerLeft {
    width: 70%;
  }

  .banner .flowerRight {
    width: 70%;
  }

  .banner .media {
    max-width: 90%;
    margin-top: 90px;
  }

  .banner .aroosiOnline {
    font-size: 76px;
    line-height: 74px;
    left: 6%;
  }

  .banner .bride {
    margin-top: 80px;
  }

  /* .showExampleCard {
    margin-bottom: 80px;
  } */
  .banner {
    padding-bottom: 120px;
  }
}

@media (max-width: 800px) {
  .banner .flowerLeft {
    width: 70%;
  }

  .banner .flowerRight {
    width: 50%;
  }

  .banner .media {
    max-width: 90%;
    margin-top: 90px;
  }
}

/* -------------- home page => about us ------------ */
.aboutUs {
  max-width: 900px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 60px auto;
  position: relative;
}

.aboutUs .context {
  width: 60%;
  text-align: center;
  padding: 70px 20px;
  background-color: #fff;
  border: 15px solid rgba(240, 229, 205, 0.609);
  /* border-left: none; */
}

@media (max-width: 850px) {
  .aboutUs .context {
    width: 90%;
    border-width: 10px;
    margin-bottom: 30px;
    /* border-left: 10px solid rgba(240, 229, 205, 0.609); */
  }
}

.aboutUs .videoBox {
  width: 30%;
  /* border: 4px solid rgba(240, 229, 205, 0.609); */
  /* padding: 4px; */
}

@media (max-width: 850px) {
  .aboutUs .videoBox {
    width: 90%;
  }
}

.aboutUs .videoBox video::-webkit-media-controls-panel {
  background-image: linear-gradient(transparent, transparent) !important;

}

/* ------------------------ counter -------------- */
.flip-clock {
  text-align: center;
  perspective: 400px;
  margin: 20px auto;
}

.flip-clock__piece {
  display: inline-block;
  margin: 0 5px;
}

.flip-clock__slot {
  font-size: 2vw;
}

.card {
  display: block;
  position: relative;
  padding-bottom: 0.72em;
  font-size: 9vw;
  line-height: 0.95;
}

.card__top,
.card__bottom,
.card__back::before,
.card__back::after {
  display: block;
  height: 0.72em;
  color: #ccc;
  background: #222;
  padding: 0.25em 0.25em;
  border-radius: 0.15em 0.15em 0 0;
  backface-visiblity: hidden;
  transform-style: preserve-3d;
  width: 1.8em;
  transform: translateZ(0);
}

.card__bottom {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 1px #000;
  background: #393939;
  border-radius: 0 0 0.15em 0.15em;
  pointer-events: none;
  overflow: hidden;
}

.card__bottom::after {
  display: block;
  margin-top: -0.72em;
}

.card__back::before,
.card__bottom::after {
  content: attr(data-value);
}

.card__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0%;
  pointer-events: none;
}

.card__back::before {
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.flip .card__back::before {
  animation: flipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .card__back .card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }

  0%,
  99% {
    opacity: 0.99;
  }

  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }

  51% {
    opacity: 0.99;
  }

  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}

/* --------------------- trees-------------- */
.treeComponent {
}

.treeComponent .treeItem {
  width: 80px;
  position: relative;
}

.treeComponent .treeItem .showNames {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  background: rgb(204, 204, 204);
  color: white;
  padding: 5px 8px;
  font-size: 12px;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  display: none;
  /* height: 38px; */
  width: max-content;
  border: 1px solid #969696;
  border-radius: 3px;
}

.treeComponent .treeItem:hover .showNames {
  display: block;
}

.formControl .error {
  color: rgb(204, 0, 0);
  font-size: 14px;
}

.formControl input:focus {
  border-color: #30336b;
}

.Toastify__toast {
  font-family: Vazir !important;
}

.Toastify__toast-body {
  direction: rtl;
}

.tableGuest,
.tableSurvery,
.tableMemento {
  min-width: "1000px";
}

.tableGuest td,
.tableGuest th,
.tableMemento td,
.tableMemento th,
.tableSurvery td,
.tableSurvery th {
  font-family: Vazir !important;
}

.apexcharts-legend-text {
  font-family: Vazir !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #474787 !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #474787c2 !important;
}

.css-b62m3t-container {
  width: 100% !important;
}

.notFoundPage {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin: 100px auto 60px;
}

.notFoundPage .notFoundImg {
  max-width: 100%;
  width: 400px;
  margin-bottom: 20px;
}

.cardpageExample .sidebarCart {
  height: calc(100vh - 78px) !important;
  margin-top: 78px;
}

.playMusicBox {
  position: fixed;
  left: 10px;
  top: 10px;
  width: 80px;
  height: 80px;
  transition: all 0.5s ease;
  z-index: 1000;
  padding: 15px;
}

.playMusicBox.animateBox {
  animation: pulse 1.5s infinite;
}

.cardpageExample .playMusicBox.smallBox{
  top: 90px;
}

.cardpageExample .playMusicBox{
  top: 90px;
}

@media (max-width: 500px) {
  .playMusicBox {
    width: 110px;
    height: 110px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cardpageExample .playMusicBox{
    top: 50%;
  }

  .playMusicBox.animateBox {
    animation: pulseM 1.5s infinite;
  }

  @keyframes pulseM {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.playMusicBox.smallBox {
  width: 55px;
  height: 55px;
  left: 20px;
  top: 20px;
  transform: translate(0, 0);
  padding: 8px;
}

.playMusicBox button {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  position: relative;
}

.playMusicBox img {
  width: 100%;
}

.playMusicBox .backgroundOverlay {
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: rgba(34, 34, 34, 0.946); 
  border-radius: 50%; 
  z-index: -1; 
  transition: all 0.5s ease; 
}

.playMusicBox.smallBox .backgroundOverlay {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 91% !important;
  }
}

/* .css-hfs6mi {
  height: 503px !important;
} */

.zarinpal {
  margin-right: 10px;
}


.wrapper-org {
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* @media (max-width: 992px) {
  .wrapper-org {
    min-height: 240px;
  }
} */

.carousel-org {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  padding: 15px 12px;
  border: 1px solid #8998bb;
  border-radius: 10px;
  background-color: #8998bb;
  opacity: 0;
  color: #fff;
  filter: drop-shadow(0px 4px 34px rgba(0, 0, 0, 0.1));
  will-change: transform, opacity;
  animation: carousel-animate-vertical 27s linear infinite;
}

.carousel__item img{
  width: 50px;
  margin-left: 10px;
}

.carousel__item:nth-child(1) {
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  animation-delay: calc(-3s * 2);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #d7f7fc;
  width: 90px;
  height: 90px;
  padding: 14px;
  position: relative;
  margin-right: -45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 20px 16px 70px;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 10px;
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  11.1111111111% {
    transform: translateY(150%) scale(0.6);
    opacity: 0.4;
    visibility: visible;
    z-index: 3;
  }
  14.1111111111%,
  22.2222222222% {
    transform: translateY(80%) scale(0.8);
    opacity: 0.8;
    visibility: visible;
    z-index: 4;
  }
  25.2222222222%,
  33.3333333333% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }

  36.3333333333%,
  44.4444444444% {
    transform: translateY(-80%) scale(0.8);
    opacity: 0.8;
    visibility: visible;
    z-index: 4;
  }
  47.4444444444%,
  55.5555555555% {
    transform: translateY(-150%) scale(0.6);
    opacity: 0.4;
    visibility: visible;
    z-index: 3;
  }
  58.5555555555% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

.offPackageBtn {
  position: relative;
  display: inline-block;
  z-index: 2;
  text-decoration: none;
  color: #ff0000;
  padding: 11px 20px;
  border: 1px solid #eb1515;
  margin-top: 10px;
  transition: all 0.3s;
  margin-left: 10px;
  background-color: transparent;
}

.offPackageBtn:hover {
  color: #efefef;
  padding: 11px 20px;
  /* border-color: rgba(240, 229, 205, 0.609); */
  background-color: rgb(227, 32, 32);
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
