.planSmsPage {
  flex-direction: row !important;
  flex-wrap: wrap;
  overflow: hidden;
  border: none !important;
}

@media (max-width: 500px) {
  .planSmsPage {
    background-size: contain;
  }
}

.planSmsPage .plan {
  width: 200px;
  height: 400px;
  margin: 10px 5px;
  position: relative;
  background-color: #30336b;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.planSmsPage .plan .iconPlan {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 45px;
}

.planSmsPage .plan .content {
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 20px;
  text-align: center;
  color: #fff;
  padding-top: 40px;
  position: relative;
}

.planSmsPage .plan .title {
  margin-bottom: 20px;
}

.planSmsPage .plan .cost {
  margin-bottom: 25px;
  font-size: 25px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 20px !important;
}

.planSmsPage .plan .buy {
  background: linear-gradient(180deg, #f0ede8 0%, #ddd4c3 100%);
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 8px;
  transition: all 0.3s;
  width: 100%;
}

.planSmsPage .plan:hover .buy {
  transition: all 0.3s ease-in-out;
}

.planSmsPage .plan .buy:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: translateY(-2px);
}

.planSmsPage .flowerLeft {
  position: absolute;
  left: 0;
  top: 0;
  width: 600px;
  max-width: 100%;
  z-index: 2;
}

.planSmsPage .flowerRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 530px;
  max-width: 100%;
  z-index: 2;
}

.planSmsPage .flowerBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.planSmsPage .disable {
  color: rgb(200, 200, 200);
  text-decoration: line-through;
}

.planSmsPage .addToCart {
  display: inline-block;
  padding: 10px 40px;
  border-radius: 30px;
  /* border: 1px solid #c2c2c2; */
  background-color: #a43043;
  color: rgb(240, 248, 255);
  transition: all 0.3s;
  position: relative;
  z-index: 2;
}

.planSmsPage .addToCart:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-5px);
  border: none;
}

@media (max-width: 500px) {
  .planSmsPage .plan {
    width: 100%;
    height: auto;
    margin: 10px 5px;
  }
  .planSmsPage .plan .content {
    display: flex;
    flex-direction: column;
  }
  .planSmsPage .plan .iconPlan {
    position: absolute;
    width: 50px;
    height: 50px;
  }
  .planSmsPage .plan .buy {
    margin-top: 0px;
  }
}
