@font-face {
  font-family: "Vazir";
  src: url("../public/asset/font/Lalezar.ttf") format("truetype"),
    url("../public/asset/font/Lalezar.woff") format("truetype");
  /* url('../public/asset/font/Vazir.eot') format('eot'),
       url('../public/asset/font/Vazir.woff2') format('woff2'); */
}

@font-face {
  font-family: "IranNastaliq";
  src: url("../public/asset/font/IranNastaliq.ttf") format("truetype");
}

@font-face {
  font-family: "ShekastehHellolove";
  src: url("../public/asset/font/Shekasteh-hellolove.ir.ttf") format("truetype");
}

@font-face {
  font-family: "Parisienne";
  src: url("../public/asset/font/Parisienne-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Vazir, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
